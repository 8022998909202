@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&family=Roboto:wght@300;400;500;700;900&display=swap');
@import "variables";
@import "mixin";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $rubik;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

section {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;

  @include res(1200) {
    flex-wrap: wrap;

    br {
      display: none;
    }
  }

  h2 {
    font-size: 21px;
    line-height: 28px;
    text-align: justify;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFDD15;

    @include res(1200) {
      font-size: 18px;
      line-height: 24px;
    }

    @include res(600) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 72px;
    line-height: 85px;
    text-transform: uppercase;
    color: #181818;
    margin: 16px 0 64px;

    @include res(1200) {
      font-size: 62px;
      line-height: 75px;
      margin: 16px 0 54px;
    }

    @include res(998) {
      font-size: 48px;
      line-height: 60px;
      margin: 10px 0 44px;
    }

    @include res(600) {
      font-size: 32px;
      line-height: 38px;
    }

    @include res(400) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  a {
    outline: none;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
    position: relative;
    color: #181818;
    padding: 25px 0px 25px 24px;
    border-radius: 35px;

    span {
      border-radius: 35px;
      width: 70px;
      height: 70px;
      z-index: -1;
      top: 0px;
      left: 0px;
      position: absolute;
      background: #FFDD15;
      -webkit-box-shadow: 0px 10px 20px -8px rgba(255, 221, 21, 1);
      -moz-box-shadow: 0px 10px 20px -8px rgba(255, 221, 21, 1);
      box-shadow: 0px 10px 20px -8px rgba(255, 221, 21, 1);
    }

    img {
      padding-left: 12px;
      transition: padding-right 0.25s ease;
    }

    &:hover {

      &:after {
        width: 100%;
      }

      img {
        padding-left: 16px;
        transition: padding-left 0.25s ease;
      }
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 35px;
      width: 0;
      height: 100%;
      background-color: #FFDD15;
      transform-origin: left;
      transition: width 0.25s ease;
      z-index: -1;
      border-radius: 0 35px 35px 0;
    }

    &.dark {
      color: #FFFFFF;
      z-index: 999;
      padding: 25px 24px;

      span {
        background: transparent;
        border: none;
        box-shadow: none;
      }

      &:after {
        background: transparent;
        height: 68px;
        width: 70px;
        left: 0;
        border: 1px solid #FFDD15;
        border-radius: 35px;
      }

      &:hover {

        span {
          border: none;
        }

        &:after {
          border: 1px solid #FFDD15;
          border-radius: 35px;
          width: 100%;
        }
      }
    }
  }
}

.hamburger-menu {
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  width: 124px;
  height: 98px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .5s ease;
  z-index: 99999;
  padding: 18px 0;

  @include res(600) {
    transform: scale(0.6);
    top: -20px;
    right: -25px;
  }

  .bar {
    width: 40px;
    height: 4px;
    border-radius: 3px;

    &:after, &:before {
      width: 40px;
      height: 4px;
      border-radius: 3px;
    }

    position: relative;
    transform: translateY(25px);
    transition: all 0ms 300ms;

    &.animate {
      background: rgba(255, 255, 255, 0);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 25px;
      background: #000000;
      transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      margin-bottom: -22px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 25px;
      background: #000000;
      transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      margin-top: -13px;
      width: 25px;
      margin-left: 15px;
    }

    &.animate {
      &:after {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
        margin-top: 0px;
        width: 40px;
        margin-left: 0px;
      }

      &:before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
        margin-bottom: 0;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.button_block {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 20px;

  @include res(600) {
    padding: 8px;
  }

  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    margin: 0 10px;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid #FFDD15;
    }

    @include res(600) {
      font-size: 11px;
      line-height: 16px;
    }
  }
}

.show {
  opacity: 1;
  width: 100%;
  z-index: 9999;
}

.hide {
  opacity: 0;
  width: 0;
  z-index: -1;
}

.mobile-nav {
  background: #181818;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 940px;
  min-height: 100vh;
  height: 100%;
  text-align: center;
  transition: opacity .5s ease;
  -moz-transition: opacity .5s ease;
  -webkit-transition: opacity .5s ease;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @include res(950) {
    max-width: 100%;
  }

  a {
    font-weight: bold;
    font-size: 56px;
    line-height: 66px;
    text-transform: uppercase;
    color: #7A7A7A;
    margin: 0 120px 48px;
    text-decoration: none;
    text-align: left;
    position: relative;

    @include res(950) {
      margin: 0 60px 38px;
      font-size: 40px;
      line-height: 46px;
    }

    @include res(600) {
      margin: 0 30px 28px;
      font-size: 26px;
      line-height: 32px;
    }

    &:first-child {
      padding-top: 180px;

      @include res(1200) {
        padding-top: 140px;
      }

      @include res(950) {
        padding-top: 80px;
      }
    }

    &:hover, &.active {
      color: #FFFFFF;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        width: 100px;
        border-radius: 2px;
        border-bottom: 1px solid #FFDD15;

        @include res(950) {
          width: 50px;
        }

        @include res(600) {
          width: 30px;
        }
      }
    }
  }

  p {
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #7A7A7A;
    text-align: left;
    margin: 0 0px 0px 120px;
    position: absolute;
    bottom: 20px;

    @include res(950) {
      margin: 0 0px 0 60px;
    }

    @include res(600) {
      margin: 0 0px 0 30px;
    }
  }
}

#home {

  .text-block {
    max-width: 44%;
    color: #181818;
    padding: 42px 60px 100px 75px;

    @include res(1200) {
      max-width: 100%;
      padding: 32px 55px 80px 55px;
    }

    @include res(600) {
      padding: 22px 20px 40px 20px;
    }

    .logo {
      margin-bottom: 140px;
      margin-left: -35px;

      @include res(1200) {
        margin-bottom: 90px;
      }

      @include res(998) {
        margin-bottom: 60px;
      }

      @include res(600) {
        margin-bottom: 40px;
        margin-left: 0;
      }
    }

    h1 {
      font-weight: 700;
      font-size: 72px;
      line-height: 85px;
      text-transform: uppercase;
      color: #181818;
      position: absolute;

      @include res(1200) {
        position: relative;
        font-size: 66px;
        line-height: 72px;
      }

      @include res(998) {
        font-size: 55px;
        line-height: 62px;
      }

      @include res(600) {
        font-size: 40px;
        line-height: 46px;
      }

      @include res(400) {
        font-size: 32px;
        line-height: 40px;
      }

    }

    h2 {
      margin-top: 220px;
      font-weight: 300;
      font-size: 21px;
      line-height: 28px;
      text-align: inherit;
      letter-spacing: normal;
      text-transform: inherit;
      color: #181818;

      span {
        font-weight: 700;
      }

      @include res(1200) {
        margin-top: 30px;
      }

      @include res(600) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    h3 {
      margin: 46px 0 64px;
      font-style: italic;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
    }
  }

  .img-block {
    background-image: url("../images/pic_1.jpg");
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    width: -webkit-fill-available;
    background-size: 100%;
    background-position: bottom;
    background-color: #fdef8f;

    @include res(1200) {
      display: none;
    }
  }
}

.dark_block {

  .text-block {
    max-width: 50%;
    background: #181818;
    padding: 244px 180px 60px 75px;
    width: 100%;

    @include res(1200) {
      padding: 140px 55px 60px 55px;
      max-width: 100%;
    }

    @include res(600) {
      padding: 100px 20px 60px;
    }

    h3 {
      margin: 16px 0 48px;
      color: #FFFFFF;
    }

    h2 {
      color: #FFDD15;
    }

    p {
      font-size: 21px;
      line-height: 28px;
      text-align: justify;
      color: #FFFFFF;
      margin-bottom: 24px;

      span {
        font-weight: 700;
      }

      &.last {
        margin-bottom: 64px;
      }

      @include res(600) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .img-block {
    display: flex;
    background: #FDFDFD;

    img {
      max-height: 780px;
      align-self: center;
      margin-left: -72px;
      box-shadow: -5px 20px 50px 5px rgba(0, 0, 0, 0.2);
      max-width: 100%;
    }

    @include res(1200) {
      display: none;
    }
  }
}

#WeAreProviding {

  .text-block {
    max-width: 50%;
    width: 100%;
    padding: 120px 150px 60px 75px;

    @include res(1200) {
      max-width: 100%;
      padding: 90px 55px 50px 55px;
    }

    @include res(600) {
      padding: 70px 20px 10px;
    }

    h2 {
      color: #FFDD15;
    }

    h3 {
      margin: 16px 0 64px;

      @include res(600) {
        margin: 16px 0 34px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 16px;

      @include res(600) {
        margin-bottom: 60px;
      }

      li {
        max-width: 46%;
        width: 100%;
        margin-bottom: 48px;

        @include res(600) {
          max-width: 100%;
          margin-bottom: 24px;
        }

        p {
          font-weight: bold;
          font-size: 21px;
          line-height: 28px;
          color: #181818;
          margin-top: 12px;

          @include res(600) {
            font-size: 17px;
            line-height: 22px;
            margin-top: 10px;
          }
        }

        h4 {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #181818;
          margin-top: 8px;

          @include res(600) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .img-block {
    display: flex;
    justify-content: space-between;

    .bg {
      width: 640px;
      background: #FFDD15;
      position: absolute;
      height: -webkit-fill-available;
      right: 0;
      z-index: -1;
    }

    img {
      height: fit-content;
      align-self: center;
      box-shadow: -5px 20px 50px 5px rgba(0, 0, 0, 0.2);

      &.logo {
        box-shadow: none;
      }
    }

    @include res(1200) {
      display: none;
    }
  }
}

#customerSupport {
  padding: 86px 0 80px 75px;

  @include res(1200) {
    padding: 70px 55px;
  }

  @include res(600) {
    padding: 90px 20px 50px;
  }

  .left-block {
    max-width: 50%;
    width: 100%;
    padding: 0px 50px 20px 0px;

    @include res(1200) {
      max-width: 100%;
    }

    @include res(998) {
      max-width: 100%;
      padding: 0px 0px 20px 0px;
    }

    h3 {
      margin-bottom: 42px;
    }

    p {
      font-size: 21px;
      line-height: 28px;
      text-align: justify;
      color: #181818;
      margin-bottom: 35px;

      span {
        font-weight: 700;
      }

      @include res(600) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
      }
    }

    .yellow_block {
      background: #FFDD15;
      box-shadow: -5px 20px 50px rgba(0, 0, 0, 0.2);
      background-image: url("../images/decoration_top.png");
      background-repeat: no-repeat;
      background-position: top right 40px;
      padding: 48px 40px 40px;
      max-width: 648px;
      margin: 0 auto 64px;

      @include res(998) {
        max-width: 100%;
        width: 100%;
        padding: 38px 53px 30px;
        margin: 0;
      }

      @include res(780) {
        padding: 28px 20px 20px;
      }

      h4 {
        font-weight: 500;
        font-size: 36px;
        line-height: 43px;
        text-transform: uppercase;
        color: #181818;
        margin: 0 0 48px -113px;


        @include res(998) {
          margin: 0 0 38px 0px;
          font-size: 28px;
          line-height: 32px;
        }
      }

      .d-flex {
        display: flex;
        flex-wrap: nowrap;

        @include res(800) {
          padding: 0 20px;
        }

        @include res(600) {
          flex-wrap: wrap;
        }
      }

      ul {
        max-width: 46%;
        width: 100%;
        list-style: url("../images/circle_white.svg");

        @include res(600) {
          max-width: 100%;
        }

        li {
          font-size: 21px;
          line-height: 28px;
          color: #181818;
          margin-bottom: 24px;
          padding: 0 0 0 5px;
        }
      }
    }
  }

  .right-block {
    width: 100%;
    max-width: 50%;

    @include res(1200) {
      max-width: 100%;
    }

    .black {
      background: #181818;
      padding: 48px 48px 16px;
      margin: 176px 75px 0 0;

      @include res(1200) {
        margin: 40px 0px;
      }

      @include res(780) {
        padding: 28px 20px 20px;
      }

      h4 {
        font-weight: 500;
        font-size: 36px;
        line-height: 43px;
        text-transform: uppercase;
        color: #FFFFFF;

        @include res(998) {
          font-size: 28px;
          line-height: 32px;
        }
      }

      ul {
        list-style: url("../images/circle_yellow.svg");
        display: flex;
        flex-wrap: wrap;
        padding: 48px 46px 0;
        justify-content: space-between;

        @include res(998) {
          padding: 38px 30px;
        }

        @include res(998) {
          padding: 30px 20px;
        }

        li {
          width: 100%;
          max-width: 46%;
          padding: 0 0 48px 5px;

          @include res(600) {
            max-width: 100%;
            padding: 0 0 26px 5px;
          }

          h5 {
            font-weight: normal;
            font-size: 21px;
            line-height: 28px;
            color: #FFFFFF;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            color: #7A7A7A;
            margin: 8px 0 0 0;
          }
        }
      }
    }
  }

  a {

    &.mobile {
      display: none;
    }

    @include res(1200) {

      &.mobile {
        display: block;
        margin: 40px 0 60px;
        padding: 22px 0px 21px 24px;
      }

      &.desktop {
        display: none;
      }
    }
  }
}

#contactUs {

  h2 {
    font-size: 21px;
    line-height: 28px;
    text-align: justify;
    text-transform: uppercase;
    color: #FFDD15;
    font-weight: 300;
    letter-spacing: normal;
    margin-bottom: 30px;
  }
}

#terms, #LegalMentions {
  padding: 42px 75px 50px;
  flex-direction: column;

  @include res(1200) {
    padding: 32px 55px;
  }

  @include res(600) {
    padding: 22px 20px;
  }

  .header_content {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  a {
    &.back {

      img {
        padding: 0;

        &.arrow {
          transform: scaleX(-1);
          height: fit-content;
          align-self: center;
        }
      }

      &:hover {
        img {
          padding: 0;
        }
      }
    }

    &.main{
      margin-left: -35px;

      @include res(600) {
        margin-left: 0;
      }
    }
  }

  img {
    width: fit-content;
  }

  h2 {
    font-size: 72px;
    line-height: 85px;
    text-transform: uppercase;
    color: #181818;
    margin-bottom: 64px;
    text-align: initial;
    letter-spacing: normal;

    @include res(1200) {
      font-size: 51px;
      line-height: 61px;
    }

    @include res(600) {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 21px;
    line-height: 28px;
    text-align: justify;
    color: #181818;
    margin-bottom: 38px;

    span {
      margin-top: 40px;
      font-weight: 700;
    }

    @include res(1200) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 26px;
    }

    @include res(600) {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 16px;
    }
  }

  a {
    font-size: 21px;
    line-height: 28px;
    text-align: justify;
    color: #181818;
    margin-bottom: 38px;
    font-weight: 400;
    padding: 0;
    text-transform: none;

    &:before, &:after {
      content: none;
    }

    @include res(1200) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 26px;
    }

    @include res(600) {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 16px;
    }
  }

  ul {
    list-style: none;

    li {
      font-size: 21px;
      line-height: 28px;
      text-align: justify;
      color: #181818;

      @include res(1200) {
        font-size: 18px;
        line-height: 24px;
      }

      @include res(600) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
